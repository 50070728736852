import React from "react"
// import { Link } from "gatsby"
// import classNames from "classnames"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { CDNBASE } from "../utils/maps"
import data from "../json/price.json"
import st from "./css/price.module.scss"


const PricePage = function () {

  return (
    <Layout cur='/price/' stickyHeader>
      <SEO
        title="价格"
        keywords="酷课快约价格,酷课快约系统收费,免费试用,"
        description="酷课服务预订系统采取按订单量提成模式，无固定成本，放心使用。灵活、节省、免费试用30天，公平、稳定、不临时涨价。"
      />

      <section className={st.main}>
        <h1>日租收费模式</h1>
        <p>自由充值，按天按店扣费</p>
        <div className={st.coin} style={{ backgroundImage: `url(${CDNBASE}/home/price/01.jpg)` }}></div>
        <div className={st.price}>
          <b className={st.num}>5</b><b>元</b><span>/天/店</span>
          <div className={st.refuse}>第二店起对折</div>
        </div>
        <div className={st.pig} style={{ backgroundImage: `url(${CDNBASE}/home/price/02.jpg)` }}></div>
      </section>
      {
        data.map((v, i) => (
          <section className={st.banner} style={{ backgroundColor: v.bg}} key={i}>
            <div className={st.bannerWrap}>
              <div className={st.text}>
                <h2>{v.title}</h2>
                <ul>
                  {
                    v.items.map(item => (
                      <li>{item}</li>
                    ))
                  }
                </ul>
              </div>
              <div className={st.pic} style={{ backgroundImage: `url(${CDNBASE}/home/price/${v.img})` }}></div>
            </div>
          </section>
        ))
      }

    </Layout>
  )
}

export default PricePage
